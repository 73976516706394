import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGE_URL } from '../utils/constants';

interface Page {
	label: string;
	route: string;
}

const pages: Page[] = [
	{
		label: 'About Us',
		route: '/about'
	},
	{
		label: 'Enquire',
		route: '/enquire'
	}
	// {
	// 	label: 'Projects',
	// 	route: '/projects'
	// }
];

function ResponsiveAppBar() {
	const navigate = useNavigate();

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleRoute = (route: string) => {
		handleCloseNavMenu();
		navigate(route);
	};

	return (
		<AppBar position='static' color='primary' sx={{ position: 'relative' }}>
			<Container maxWidth='xl'>
				<Toolbar disableGutters variant='dense'>
					<Box
						component='img'
						sx={{ display: { xs: 'none', md: 'flex' }, mr: 0.5, height: '1em' }}
						alt='Haystack Web and Mobile Logo'
						src={IMAGE_URL.HaystackLogo}
					/>
					<Link to='/' style={{ textDecoration: 'none' }}>
						<Typography
							variant='h6'
							noWrap
							component='a'
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'Lato',
								fontWeight: 900,
								color: 'white',
								textDecoration: 'none'
							}}
						>
							Haystack.
						</Typography>
					</Link>

					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' }
							}}
						>
							{pages.map(page => (
								<MenuItem
									key={page.label}
									onClick={() => handleRoute(page.route)}
								>
									<Typography textAlign='center'>{page.label}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Box
						component='img'
						sx={{
							display: { xs: 'flex', md: 'none' },
							mr: 0.5,
							height: '1.1em'
						}}
						alt='Haystack Web and Mobile Logo'
						src={IMAGE_URL.HaystackLogo}
					/>
					<Link to='/' style={{ textDecoration: 'none' }}>
						<Typography
							variant='h5'
							noWrap
							component='a'
							sx={{
								mr: 2,
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontFamily: 'Lato',
								fontWeight: 900,
								color: 'white',
								textDecoration: 'none'
							}}
						>
							Haystack.
						</Typography>
					</Link>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{pages.map(page => (
							<Button
								key={page.label}
								onClick={() => navigate(page.route)}
								sx={{
									color: 'white',
									display: 'block',
									textTransform: 'none',
									textDecoration: 'underline 0.15em rgba(255, 255, 255, 0)',
									transition: 'text-decoration-color 200ms',
									'&:hover': {
										textDecoration: 'underline 0.15em rgba(255, 255, 255, 1)',
									}
								}}
							>
								{page.label}
							</Button>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;

import {
	Box,
	Button,
	Container,
	Typography,
	useTheme
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const SendEmailSuccess = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<Container
			sx={{
				maxWidth: {
					xs: 'auto',
					md: '600px'
				}
			}}
		>
			<Box
				sx={{
					width: 'inherit',
					alignContent: 'start'
				}}
			>
				<Typography variant='h1' textAlign='left' paddingBottom='8px'>
					Thanks for your enquiry!
				</Typography>
				<Typography
					variant='body1'
					fontSize='0.9em'
					textAlign='left'
					paddingBottom='8px'
				>
					Thanks for getting in touch with us. We'll contact you via your
					preferred contact method within the next few business days to discuss
					your vision further.
				</Typography>
				<Button
					variant='contained'
					sx={{
						padding: '8px 32px',
						margin: '32px auto',
						backgroundColor: theme.palette.primary.light
					}}
					onClick={() => navigate('/')}
				>
					Go Home
				</Button>
			</Box>
		</Container>
	);
};

export default SendEmailSuccess;

import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Enquire from './Pages/Enquire';
import Projects from './Pages/Projects';
import Footer from './Footer';
import { useMediaQuery, useTheme } from '@mui/material';
import ResponsiveAppBar from './AppBar';
import SendEmailSuccess from './Pages/SendEmailSuccess';
import SendEmailFail from './Pages/SendEmailFail';

export default function Page() {
	const theme = useTheme();
	return (
		<Container
			disableGutters
			sx={{
				padding: useMediaQuery(theme.breakpoints.up('sm')) ? '0 32px' : '0'
			}}
		>
			<ResponsiveAppBar />
			<Container
				sx={{
					backgroundColor: '#ffffffaa',
					boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.08)'
				}}
			>
				<Box
					sx={{
						my: 4,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						paddingHorizontal: {
							xs: '8px',
							sm: '16px'
						},
						margin: '0 auto',
						backgroundColor: 'white',
						minHeight: '100vh'
					}}
				>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/about' element={<AboutUs />} />
						<Route path='/enquire' element={<Enquire />} />
						<Route path='/enquireSuccess' element={<SendEmailSuccess />} />
						<Route path='/enquireFail' element={<SendEmailFail />} />
						<Route path='/projects' element={<Projects />} />
					</Routes>
				</Box>
			</Container>
			<Container disableGutters>
				<Footer />
			</Container>
		</Container>
	);
}

import { FIELD_NAMES, REGEX } from "../../utils/constants";

export const VALIDATIONS = {
	[FIELD_NAMES.FIRST_NAME]: (val: string) => {
		if (val.length === 0) {
			return 'We need your first name.';
		} else if(val.length > 30) {
			return 'You can\'t enter more than 30 characters.';
    } else return '';
	},
	[FIELD_NAMES.LAST_NAME]: (val: string) => {
		if (val.length === 0) {
			return 'We need your last name.';
		} else if(val.length > 30) {
			return 'You can\'t enter more than 30 characters.';
    } else return '';
	},
  [FIELD_NAMES.ORGANISATION]: (val: string) => {
    if(val.length > 50) {
			return 'You can\'t enter more than 50 characters.';
    } else return '';
	},
  [FIELD_NAMES.EMAIL]: (val: string) => {
    if(!val.match(REGEX.EMAIL) || val.length === 0 || val.length > 64) {
			return 'We need a valid email address.';
    } else return '';
	},
  [FIELD_NAMES.PHONE]: (val: string) => {
    if(!val.match(REGEX.PHONE) || val.length < 5 || val.length > 20) {
			return 'We need a valid phone number';
    } else return '';
	},
  [FIELD_NAMES.CONTACT_METHOD]: (val: string) => {
    if(val.length === 0) {
			return 'We need a preferred contact method';
    } else return '';
	},
  [FIELD_NAMES.SERVICES]: (val: string) => {
    if(val.length === 0) {
			return 'We need to know your desired service(s)';
    } else return '';
	},
  [FIELD_NAMES.PLATFORM]: (val: string) => {
    if(val.length === 0) {
			return 'We need to know your desired platforms(s)';
    } else return '';
	},
  [FIELD_NAMES.DESC]: (val: string) => {
    if(val.length === 0) {
			return 'We need some details about your application(s)';
    } else if(val.length > 500) {
			return 'You can\'t enter more than 500 characters.';
    } else return '';
	},
};

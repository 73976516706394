import { Box, Button, Container, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const SendEmailFail = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<Container
			sx={{
				maxWidth: {
					xs: 'auto',
					md: '600px'
				}
			}}
		>
			<Box
				sx={{
					width: 'inherit',
					alignContent: 'start'
				}}
			>
				<Typography variant='h1' textAlign='left' paddingBottom='8px'>
					Uh Oh...
				</Typography>
				<Typography
					variant='body1'
					fontSize='0.9em'
					textAlign='left'
					paddingBottom='8px'
				>
					Looks like there was a problem submitting your enquiry this time.
				</Typography>
				<Typography variant='body1' fontSize='0.9em' textAlign='left'>
					You're welcome to try submitting again now, waiting a while before
					trying again, or sending us an email directly at{' '}
					<Link href='mailto:calumhay@haystackdev.au'>
						calumhay@haystackdev.au
					</Link>
					.
				</Typography>
				<Button
					variant='contained'
					sx={{
						padding: '8px 32px',
						margin: '32px auto',
						backgroundColor: theme.palette.primary.light
					}}
					onClick={() => navigate('/enquire')}
				>
					Go Back
				</Button>
			</Box>
		</Container>
	);
};

export default SendEmailFail;

import {
	FormControl,
	FormHelperText,
	TextField,
	Typography
} from '@mui/material';
import { VALIDATIONS } from './validations';
import { TextInputProps } from './types';

export const TextArea = ({
	label,
	data,
	setData,
	optional = false
}: TextInputProps) => {
	const hasError = (val) => VALIDATIONS[label]?.(val) !== '';
	const remainingChars: number = 500 - data.value.length;
	return (
		<FormControl
			sx={{
				alignItems: 'start',
				width: 'inherit'
			}}
		>
			<Typography variant='h2' marginBottom='4px'>{label}</Typography>
			<TextField
				fullWidth
				value={data.value}
				onChange={event =>
					setData({
						...data,
						value: event.target.value,
						hasError: hasError(event.target.value)
					})
				}
				onBlur={event =>
					setData({
						...data,
						touched: event.target.value.length > 0
					})
				}
				required={!optional}
				color='secondary'
				multiline
				minRows='5'
				maxRows='5'
				inputRef={data.ref}
			/>
			<FormHelperText error={data.touched && hasError(data.value)}>
				{(data.touched && hasError(data.value)) ? VALIDATIONS[label]?.(data.value) : `${remainingChars} char(s) remaining`}
			</FormHelperText>
		</FormControl>
	);
};

import { CheckBox, EditNote } from '@mui/icons-material';
import {
	Box,
	Button,
	Container,
	SxProps,
	Theme,
	Typography,
	useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ImageBox from '../ImageBox';
import { IMAGE_URL } from '../../utils/constants';

const BusinessLogo = () => {
	return (
		<Box
			display='flex'
			sx={{
				width: '100%',
				paddingTop: '32px',
				paddingBottom: '16px',
			}}
		>
			<ImageBox
				src={IMAGE_URL.HaystackMainLogo}
				variant='rounded'
				aspectRatio='auto 3 / 1'
				alt='Haystack Logo'
			/>
		</Box>
	);
};

const EnquireButton = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<Button
			variant='contained'
			endIcon={<EditNote />}
			sx={{
				padding: '8px 32px',
				margin: '32px auto',
				backgroundColor: theme.palette.primary.light
			}}
			onClick={() => navigate('/enquire')}
			size='large'
		>
			Enquire
		</Button>
	);
};

const OpeningSpiel = () => {
	const style: SxProps<Theme> = {
		textAlign: 'left',
		margin: {
			xs: 'auto 8px',
			md: 'auto 32px'
		}
	};

	return (
		<Box alignContent='left'>
			<Typography variant='body2' sx={style}>
				Have a vision for a new website or mobile app? Whether for business or
				otherwise, you've found the right place.
			</Typography>
			<Typography variant='body2' sx={style}>
				Let's talk about it.
			</Typography>
		</Box>
	);
};

const ChecklistItem = ({ children }: any) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignContent: 'center',
				alignItems: 'center',
				marginBottom: '16px'
			}}
		>
			<CheckBox
				color='secondary'
				sx={{
					display: 'flex',
					width: '1.2em',
					height: '1.2em',
					mr: '16px'
				}}
			/>
			<Typography
				sx={{
					textAlign: 'left'
				}}
			>
				{children}
			</Typography>
		</Box>
	);
};

const ServicesList = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				margin: '16px 16px',
				alignItems: 'flex-start',
				maxWidth: {
					xs: '300px',
					lg: 'auto'
				},
				paddingBottom: '32px'
			}}
		>
			<Typography variant='h1' marginBottom='8px'>
				We can:
			</Typography>
			<ChecklistItem>Design and develop your dream website</ChecklistItem>
			<ChecklistItem>
				Publish your website using a cloud hosting service
			</ChecklistItem>
			<ChecklistItem>
				Design, develop and publish your mobile application to the Apple and
				Google Play Store
			</ChecklistItem>
		</Box>
	);
};

const Home = () => {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					md: 'row'
				},
				justifyContent: 'center',
				alignContent: 'center',
				alignItems: 'center'
			}}
		>
			<Box
				flex='3'
				sx={{
					margin: {
						xs: 'auto',
						sm: 'auto 32px'
					},
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<BusinessLogo />
				<OpeningSpiel />
				<EnquireButton />
			</Box>
			<Box flex='2'>
				<ServicesList />
			</Box>
		</Container>
	);
};

export default Home;

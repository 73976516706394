export const FIELD_NAMES = {
	FIRST_NAME: 'First name',
	LAST_NAME: 'Last name',
	ORGANISATION: 'Organisation',
	EMAIL: 'Email',
	PHONE: 'Phone',
	CONTACT_METHOD: 'Preferred contact method',
	SERVICES: "I'm interested in:",
	PLATFORM: 'For a:',
	DESC: 'Tell us about your idea:'
};

export const REGEX = {
	EMAIL: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/,
	PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
};

export const CONTACT_OPTIONS = ['Email', 'Text', 'Phone call'];
export const SERVICE_OPTIONS = ['Design', 'Develop', 'Publishing'];
export const PLATFORM_OPTIONS = ['Website', 'Mobile Application'];

export const IMAGE_URL = {
	CalumHayPortrait: 'https://haystackdevstorage.blob.core.windows.net/img/CalumHayPortrait.jpg',
	HaystackLogo: 'https://haystackdevstorage.blob.core.windows.net/img/HaystackLogo.png',
	HaystackMainLogo: 'https://haystackdevstorage.blob.core.windows.net/img/HaystackMainLogo.png',
	PexelsNikiemmert: 'https://haystackdevstorage.blob.core.windows.net/img/pexels-nikiemmert-9141262.jpg',
	AboutHaystack: 'https://haystackdevstorage.blob.core.windows.net/img/AboutHaystack.jpg',
}


export const initData = (ref) => {
	return {
		value: '',
		touched: false,
		ref,
		hasError: true
	};
};

export const initCheckboxData = (ref) => {
	return {
		value: [],
		touched: false,
		ref,
		hasError: true
	};
};

import {
	Box,
	Button,
	Container,
	Typography,
	useMediaQuery,
	useTheme,
	CircularProgress
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
	CONTACT_OPTIONS,
	FIELD_NAMES,
	PLATFORM_OPTIONS,
	SERVICE_OPTIONS
} from '../../utils/constants';
import { initCheckboxData, initData } from '../../utils/helper';
import { StyledTextInput } from '../Form/TextField';
import { StyledRadioGroup } from '../Form/RadioGroup';
import { CheckBoxGroup } from '../Form/CheckboxGroup';
import { TextArea } from '../Form/TextArea';
import { Send } from '@mui/icons-material';
import { apiCall } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const H2 = ({ children }: any) => {
	return (
		<Typography variant='h2' marginBottom='6px'>
			{children}
		</Typography>
	);
};

const Section = ({ children }: any) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					sm: 'row'
				},
				alignItems: 'start',
				marginBottom: '16px',
				width: '100%'
			}}
		>
			{children}
		</Box>
	);
};

const Enquire = () => {
	const theme = useTheme();
	const [firstName, setFirstName] = useState(initData(useRef()));
	const [lastName, setLastName] = useState(initData(useRef()));
	const [org, setOrg] = useState(initData(useRef()));
	const [email, setEmail] = useState(initData(useRef()));
	const [phone, setPhone] = useState(initData(useRef()));
	const [contactMethod, setContactMethod] = useState(initData(useRef()));
	const [services, setServices] = useState(initCheckboxData(useRef()));
	const [platform, setPlatform] = useState(initCheckboxData(useRef()));
	const [desc, setDesc] = useState(initData(useRef()));
	const [isNotRobot, setIsNotRobot] = useState(false);

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const data = [
		firstName,
		lastName,
		org,
		email,
		phone,
		contactMethod,
		services,
		platform,
		desc
	];
	const setData = [
		setFirstName,
		setLastName,
		setOrg,
		setEmail,
		setPhone,
		setContactMethod,
		setServices,
		setPlatform,
		setDesc
	];

	const setAllTouched = () => {
		data.forEach((datum: any, index) => {
			setData[index]({
				...datum,
				touched: true
			});
		});
	};

	const onSubmit = async () => {
		let errorRefs: any[] = [];
		data.forEach(val => {
			if (val.hasError) {
				errorRefs.push(val.ref);
			}
		});

		if (errorRefs.length > 0) {
			setAllTouched();
			errorRefs[0].current.focus();
		} else {
			setIsLoading(true);
			const dataObject = {
				firstName: firstName.value,
				lastName: lastName.value,
				org: org.value,
				email: email.value,
				phone: phone.value,
				contactMethod: contactMethod.value,
				services: services.value.join(', '),
				platform: platform.value.join(', '),
				desc: desc.value
			};
			let response;
			try {
				response = await apiCall(dataObject);
				navigate('/enquireSuccess');
			} catch (e) {
				console.log(JSON.stringify(response));
				navigate('/enquireFail');
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				paddingX: {
					xs: '12px',
					md: '64px'
				},
				paddingBottom: '64px'
			}}
		>
			<Typography
				variant='h1'
				sx={{
					margin: '32px 0 16px'
				}}
			>
				Enquiry Form
			</Typography>
			<H2>Name</H2>
			<Section>
				<StyledTextInput
					label={FIELD_NAMES.FIRST_NAME}
					placeholder={FIELD_NAMES.FIRST_NAME}
					data={firstName}
					setData={setFirstName}
				/>
				<StyledTextInput
					label={FIELD_NAMES.LAST_NAME}
					placeholder={FIELD_NAMES.LAST_NAME}
					data={lastName}
					setData={setLastName}
				/>
			</Section>
			<H2>Organisation</H2>
			<Section>
				<StyledTextInput
					label={FIELD_NAMES.ORGANISATION}
					data={org}
					setData={setOrg}
					optional
				/>
			</Section>
			<Section>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
						width: useMediaQuery(theme.breakpoints.down('sm'))
							? '100%'
							: 'auto',
						marginBottom: '16px'
					}}
				>
					<H2>Email</H2>
					<StyledTextInput
						label={FIELD_NAMES.EMAIL}
						data={email}
						setData={setEmail}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
						width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 'auto'
					}}
				>
					<H2>Phone</H2>
					<StyledTextInput
						label={FIELD_NAMES.PHONE}
						data={phone}
						setData={setPhone}
					/>
				</Box>
			</Section>
			<Box>
				<StyledRadioGroup
					label={FIELD_NAMES.CONTACT_METHOD}
					data={contactMethod}
					setData={setContactMethod}
					options={CONTACT_OPTIONS}
				/>
			</Box>
			<H2>Services</H2>
			<Section>
				<CheckBoxGroup
					label={FIELD_NAMES.SERVICES}
					data={services}
					setData={setServices}
					options={SERVICE_OPTIONS}
				/>
			</Section>
			<Section>
				<CheckBoxGroup
					label={FIELD_NAMES.PLATFORM}
					data={platform}
					setData={setPlatform}
					options={PLATFORM_OPTIONS}
				/>
			</Section>
			<Section>
				<TextArea label={FIELD_NAMES.DESC} data={desc} setData={setDesc} />
			</Section>
			<Box paddingBottom='32px'>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
					onChange={() => setIsNotRobot(true)}
					onErrored={() => setIsNotRobot(false)}
					onExpired={() => setIsNotRobot(false)}
				/>
			</Box>
			{isLoading ? (
				<Box
					sx={{
						width: 'inherit',
						alignContent: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Button
					variant='contained'
					endIcon={<Send />}
					sx={{
						padding: '8px 16px',
						margin: '16px auto',
						backgroundColor: theme.palette.primary.light
					}}
					onClick={() => onSubmit()}
					disabled={!isNotRobot}
				>
					Send Enquiry
				</Button>
			)}
		</Container>
	);
};

export default Enquire;

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { VALIDATIONS } from './validations';
import { CheckboxProps } from './types';

export const CheckBoxGroup = ({
	label,
	data,
	setData,
	optional = false,
	options
}: CheckboxProps) => {
	const theme = useTheme();

	const hasError = (val) => VALIDATIONS[label](val) !== '';

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.checked
		? data.value.concat(event.target.value)
		: data.value.filter(val => val !== event.target.value);
		setData({
			...data,
			value: newValue,
			touched: true,
			hasError: hasError(newValue)
		});
	};

	return (
		<FormControl
			error={
				data.touched && VALIDATIONS[label](data.value.slice().join(',')) !== ''
			}
      sx={{
				alignItems: 'start'
			}}
			color='secondary'
		>
			<FormLabel component='legend'>
				{label}
			</FormLabel>
			<FormGroup row={useMediaQuery(theme.breakpoints.up('sm'))} >
				{options.map((o, index) => {
					return (
						<FormControlLabel
							control={<Checkbox value={o} onChange={handleChange} color='secondary'  inputRef={index === 0 ? data.ref : null}/>}
							label={o}
						/>
					);
				})}
			</FormGroup>
			<FormHelperText>
				{data.touched && VALIDATIONS[label](data.value.slice().join(','))}
			</FormHelperText>
		</FormControl>
	);
};

import Box from '@mui/material/Box';
import { IMAGE_URL } from '../utils/constants';

const SplashScreen = ({ fade }: any) => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				top: '0',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'white',
				position: 'fixed',
				zIndex: '99',
				animation: fade && 'ease',
				animationName: fade && 'fadeOut',
				animationDuration: fade && '0.5s'
			}}
		>
			<Box
				component='img'
				sx={{
					width: '80px',
					height: '80px',
					animation: 'ease-in-out infinite alternate',
					animationName: 'spin',
					animationDuration: '0.8s'
				}}
				alt='Haystack Web and Mobile Logo'
				src={IMAGE_URL.HaystackLogo}
			/>
		</Box>
	);
};

export default SplashScreen;

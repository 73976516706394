import axios from 'axios';

export const apiCall = async (data: object) => {
	const url: string = process.env.REACT_APP_AZ_SEND_EMAIL_URL ?? '';
	console.log('Calum', url);
	await axios.post(url, data, {
		headers: {
			'x-functions-key': process.env.REACT_APP_AZ_SEND_EMAIL_API_KEY
		}
	});
}
import { Container, Typography, useTheme } from '@mui/material';
import * as React from 'react';

const Projects = () => {
    const theme = useTheme();
	return (
		<Container>
			<Typography
				variant='h3'
				sx={{
					mr: 2,
					fontFamily: 'Lato',
					fontWeight: 900,
                    color: theme.palette.secondary.contrastText,
					textDecoration: 'none'
				}}
			>
				Projects
			</Typography>
		</Container>
	);
};

export default Projects;

import * as React from 'react';
import Box from '@mui/material/Box';
import {
	Link,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

const PrivacyLink = styled(Link)({
	color: 'white',
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'underline'
	}
});

const FooterText = styled(Typography)(({ theme }) => ({
	margin: useMediaQuery(theme.breakpoints.up('md'))
		? '0px 16px'
		: '2px 16px 2px',
	textAlign: useMediaQuery(theme.breakpoints.up('sm')) ? 'center' : 'start',
	textDecoration: 'underline 0.05em rgba(255, 255, 255, 0)',
	transition: 'text-decoration-color 200ms',
	'&:hover': {
		textDecoration: 'underline 0.05em rgba(255, 255, 255, 1)'
	}
}));

export default function Footer() {
	const theme = useTheme();
	return (
		<Box
			sx={{
				py: useMediaQuery(theme.breakpoints.up('md')) ? 0.2 : 0.5,
				display: 'flex',
				flexDirection: useMediaQuery(theme.breakpoints.up('md'))
					? 'row'
					: 'column',
				justifyContent: 'start',
				alignItems: 'start',
				paddingHorizontal: '16px',
				backgroundColor: theme.palette.primary.main
			}}
		>
			<FooterText variant='subtitle1' flex='1'>
				<PrivacyLink href={'/haystackPrivacyPolicy.pdf'} target='_blank'>
					Privacy Policy
				</PrivacyLink>
			</FooterText>
			<FooterText variant='subtitle1' flex='2'>
				Copyright © 2024 Haystack Web&Mobile
			</FooterText>
		</Box>
	);
}

import { Box, Container, Typography, useTheme } from '@mui/material';
import ImageBox from '../ImageBox';
import { IMAGE_URL } from '../../utils/constants';

const Paragraph = ({ children, sx }: any) => {
	return (
		<Typography
			variant='body1'
			sx={{
				textAlign: 'start',
				marginBottom: '16px',
				...sx
			}}
		>
			{children}
		</Typography>
	);
};

const AboutUs = () => {
	const theme = useTheme();

	return (
		<Container
			sx={{
				maxWidth: {
					xs: 'auto',
					sm: '600px'
				},
				margin: '32px 16px 64px'
			}}
		>
			<Box
				sx={{
					boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.19)'
				}}
			>
				<ImageBox
					src={IMAGE_URL.AboutHaystack}
					backgroundColor={theme.palette.primary.main}
					animation='wave'
					variant='rectangular'
					aspectRatio='auto 1500 / 683'
					alt='Haystack company workspace'
				/>
				<Box
					sx={{
						backgroundColor: theme.palette.primary.light,
						padding: '4px',
						marginBottom: '16px'
					}}
				>
					<Typography
						variant='h1'
						sx={{
							color: 'white',
							fontWeight: '600',
							fontSize: '1.2em'
						}}
					>
						About Haystack.
					</Typography>
				</Box>
			</Box>
			<Paragraph>
				At Haystack Web & Mobile, we’re passionate about empowering local
				businesses or organisations with tailored web and mobile applications
				that drive success and growth.
			</Paragraph>
			<Paragraph>
				With years of experience in frontend application development, we
				specialize in crafting custom applications that address the unique needs
				of small to mid-sized enterprises. Our mission is to design, develop and
				publish high-quality and affordable software solutions to local
				businesses and organisations; helping them expand their digital
				presence, enhance customer experiences, and achieve their goals.
			</Paragraph>
			<Paragraph>
				By working closely with each client, we ensure that every project is not
				only technically sound but also aligned with your business objectives.
				Together, we can transform your vision into a functional, efficient, and
				impactful digital solution.
			</Paragraph>

			<Typography
				variant='h1'
				sx={{
					textAlign: 'start',
					marginTop: '32px'
				}}
			>
				About Me
			</Typography>
			<Box
				sx={{
					width: '100%',
					height: '4px',
					backgroundColor: theme.palette.secondary.main,
					marginBottom: '16px'
				}}
			/>
			<Box
				display='flex'
				sx={{
					width: {
						xs: 'calc(100% - 48px)',
						sm: '180px'
					},
					float: {
						xs: 'none',
						sm: 'right'
					},
					padding: '8px',
					backgroundColor: 'whitesmoke',
					margin: '16px',
					boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.19)'
				}}
			>
				<ImageBox
					src={IMAGE_URL.CalumHayPortrait}
					backgroundColor={'whitesmoke'}
					animation='wave'
					variant='rectangular'
					aspectRatio='auto 1'
					alt='Selfie of Calum'
				/>
			</Box>
			<Paragraph sx={{ marginTop: '16px' }}>Hi! I'm Calum.</Paragraph>
			<Paragraph>
				I'm an easygoing freelance software developer with a keen focus on
				creating web and mobile application solutions tailored to my clients'
				needs.
			</Paragraph>
			<Paragraph>
				I graduated from Swinburne University of Technology with a double degree
				in Robotics & Mechatronics Engineering and Computer Science. With a
				professional background in frontend development at a 'Big 4' bank using
				ReactJS and AWS (Amazon Web Services), I specialize in building
				adaptable websites and mobile applications that are as much secure as
				they drive business growth.
			</Paragraph>
			<Paragraph>
				I'm an avid problem solver, and a Google Search wizard (essential for
				any programmer). When I’m not coding, you might find me playing my
				Nintendo Switch, riding my bike, or helping out at my local church.
			</Paragraph>
		</Container>
	);
};

export default AboutUs;

import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Radio,
	RadioGroup,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { VALIDATIONS } from './validations';
import { RadioInputProps } from './types';

export const StyledRadioGroup = ({
	label,
	data,
	setData,
	optional = false,
	options
}: RadioInputProps) => {
	const theme = useTheme();
	const id = label.toLocaleLowerCase().replace(' ', '-');
	
	const hasError = (val) => VALIDATIONS[label]?.(val) !== '';
	
	return (
		<FormControl
			error={data.touched && VALIDATIONS[label]?.(data.value) !== ''}
			sx={{
				alignItems: 'start',
				marginBottom: '16px'
			}}
		>
			<Typography variant='h2'>
				{label}
			</Typography>
			<RadioGroup
				row={useMediaQuery(theme.breakpoints.up('sm'))}
				aria-labelledby={id}
				name={`${id}-radio-buttons-group`}
				value={data.value}
				onChange={event =>
					setData({
						value: event.target.value,
						touched: true,
						hasError: hasError(event.target.value)
					})
				}
			>
				{options.map((o, index) => (
					<FormControlLabel
						value={o}
						control={<Radio color='secondary' />}
						label={o}
						inputRef={index === 0 ? data.ref : null}
					/>
				))}
			</RadioGroup>
			<FormHelperText>
				{data.touched && VALIDATIONS[label]?.(data.value)}
			</FormHelperText>
		</FormControl>
	);
};

import { TextField, useMediaQuery, useTheme } from '@mui/material';
import { VALIDATIONS } from './validations';
import { TextInputProps } from './types';
import { useEffect } from 'react';

export const StyledTextInput = ({
	label,
	data,
	setData,
	optional = false,
	placeholder = ''
}: TextInputProps) => {
	useEffect(() => {
		if(optional) {
			setData({
				...data,
				hasError: false
			});
		}
	}, [data, optional, setData]);
	const theme = useTheme();
	const helperText = () => {
		if (data.touched && VALIDATIONS[label]?.(data.value)) {
			return VALIDATIONS[label]?.(data.value);
		} else return optional ? 'optional' : '';
	};
	const hasError = (val) => VALIDATIONS[label]?.(val) !== '';
	return (
		<TextField
			sx={{
				marginRight: '16px',
				marginBottom: '8px'
			}}
			variant='outlined'
			color='secondary'
			size='small'
			value={data.value}
			onChange={event =>
				setData({
					...data,
					value: event.target.value,
				})
			}
			onBlur={event =>
				setData({
					...data,
					value: data.value.trim(),
					touched: event.target.value.length > 0,
					hasError: hasError(event.target.value)
				})
			}
			required={!optional}
			error={data.touched && VALIDATIONS[label]?.(data.value) !== ''}
			helperText={helperText()}
			fullWidth={useMediaQuery(theme.breakpoints.down('sm'))}
			placeholder={placeholder}
			inputRef={data.ref}
		/>
	);
};
